import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface alertModal{
    type?: 'SUCCESS' | 'WARNING' | 'ERROR' | 'INFO',
    message?: string,
    details?: string,
    action?: string,
    duration?: number,
}

@Injectable({
    providedIn: 'root'
})
export class UiService {
    drawerStatus = new BehaviorSubject<boolean>(true);
    $drawerStatus = this.drawerStatus.asObservable();

    alertStatus = new BehaviorSubject<alertModal>({});
    $alertStatus = this.alertStatus.asObservable();

    alertButton = new BehaviorSubject<string>('');
    $alertButton = this.alertButton.asObservable();

    setDrawerStatus(val: boolean){
        this.drawerStatus.next(val)
    }

    clickAlertButton(val: string){
        this.alertButton.next(val)
    }

    setAlertStatus(val: alertModal){
        this.alertStatus.next(val)
    }
}