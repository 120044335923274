import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Util } from 'src/app/helpers/utilities';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AppService } from 'src/app/app.service';
import { AzureService } from '../azure.service';
import { CookieService } from 'ngx-cookie-service';
import { MessageUtil } from '../helpers/messages';

@Component({
  selector: 'app-regular-user',
  templateUrl: './regular-user.component.html',
  styleUrls: ['./regular-user.component.scss']
})
export class RegularUserComponent implements OnInit {
  menus = Util.sidebarMenu
  drawerOpen = true;
  open: boolean = false
  panelOpenState = false;
  firstPanelOpen: any;
  change: any;
  lightmode = 'light'
  username: any;
  name: any;
  status: any;
  selected: any;
  loginType: any;

  id: any;
  tempArray: any;

  menuList: any = []
  errorList: boolean = false
  reportList: any;
  brandLogo: any;

  brandColor: any;

  showReport: boolean = false

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  loading: boolean = false;
  list: any = [];

  logoutLoading: any;
  logoutError: boolean = false

  isReports: boolean = false;
    isTrial: boolean = false

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private route$: ActivatedRoute,
    private msalService: MsalService, private app: AppService, private cookieService: CookieService) {
    this.router.events.subscribe((route) => {
      let r = this.router.url.split('?')[0]
      r == '/user-panel/view-report' ? this.isReports = true : this.isReports = false
      this.route$.queryParams.subscribe(param => this.id = param['id'])
      if (this.id) this.showReport = true
    })
  }

  ngOnInit(): void {
    this.getList()
    this.router.navigate(['/user-panel/home-page'])
    this.isTrial = this.app.helperService.getTrial() == 'Preview' ? true : false

    this.firstPanelOpen = true
    this.name = this.app.helperService.getClientname()
    this.username = this.app.helperService.getEmail()
    this.status = this.app.helperService.getClientStatus()
    this.loginType = this.app.helperService.getLoginType()
    this.brandLogo = this.app.helperService.getLogo()
    this.brandColor = this.app.helperService.getBrand()

    if (this.brandColor) {
   
      // document.documentElement.style.setProperty(`--${'side-bar-color'}`, this.brandColor.primary); 
      document.documentElement.style.setProperty('--primary', this.brandColor.primary);
      document.documentElement.style.setProperty('--secondary', this.brandColor.secondary);
      document.documentElement.style.setProperty('--texts', this.brandColor.font);
    }
  }


  getList() {
    this.loading = true
    let email = this.app.helperService.getActiveid()
    let type = 'null'
    let status = 'null'
    this.app.productService.getBasicReport(email, type, status)
      .subscribe({
        next: (res) => {
          if (res['status'] === true) {

            this.loading = false
            this.list = res['reports']


            // this.router.navigate(['/user-panel/view-report'], { queryParams: { workspaceid: this.list[0].workspaceid, reportid: this.list[0].reportid}})
            // this.id = this.list[0].workspaceid
            this.reportList = this.list
            this.errorList = false

          } else {
            this.loading = false
            this.errorList = true
            this.reportList = []

          }
        },
        error: (err) => {
          this.loading = false
          this.errorList = true
          this.reportList = []
        }
      })
  }

  viewList(list: any) {

    window.analytics.track('select_report', {
      report_name: list.reportname,
      report_type: list.reporttype,
      report_source: list.reportource.join(','),
      report_status: list.status,
      username: this.app.helperService.getClientname(),
      datae_user_id: this.app.helperService.getActiveid(),
      plan_type: this.app.helperService.getTrial(),
      user_role: this.app.helperService.getRole(),
      organisation_name: this.app.helperService.getOrg(),
    });
      let tempItem = {reporturl: list.platformReportLink, type: list.reportPlatform}
      this.id = list['_id']
      // console.log(this.id, list);
      
      this.router.navigate(['/user-panel/view-report'], { queryParams: {id: this.id, workspaceid: list.workspaceid, reportid: list.reportid, data: JSON.stringify(tempItem)} })
    // this.router.navigate(['/user-panel/view-report'], { queryParams: {  } })
  }
  viewHome() {
    this.id = null
  }

  showReports() {
    this.showReport = true
  }
  hideReports() {
    this.showReport = false
  }

  changeMode() {
    this.lightmode = 'light'
  }
  changeDarkMode() {
    this.lightmode = 'dark'
  }

  isOpen() {
    this.open = !this.open
  }
  logout() {
    this.app.logoutUser()
    this.msalService.logout()
    localStorage.clear()
    sessionStorage.clear()
    this.cookieService.deleteAll()
  }

  trackMenu(item:any){
    window.analytics.track('menu_clicked', {
      menu_name: item,
      username: this.app.helperService.getClientname(),
      datae_user_id: this.app.helperService.getActiveid(),
      company_plan: this.app.helperService.getTrial(),
      user_title: this.app.helperService.getRole(),
      company_name: this.app.helperService.getOrg(),
    });
  }



  getInitials(string: any) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  signout() {
    this.logoutLoading = "Logging Out..."
    this.app.snackBar.open(this.logoutLoading, 'Dismiss', {
      duration: MessageUtil.TIMEOUT_DURATION,
      panelClass: ['custom-snackbar']
    })
    this.app.productService.logoutUser()
      .subscribe({
        next: (res) => {
          if (res['status'] === true) {
            this.logoutLoading = res['message']
            this.app.snackBar.open(this.logoutLoading, 'Dismiss', {
              duration: MessageUtil.TIMEOUT_DURATION,
              panelClass: ['custom-snackbar']
            })
            this.app.logoutUser()
            sessionStorage.clear()
            this.cookieService.deleteAll()
          }
          else {
            this.logoutLoading = res['message']
            this.logoutError = true
            this.app.snackBar.open(this.logoutLoading, 'Dismiss', {
              duration: MessageUtil.TIMEOUT_DURATION,
              panelClass: ['custom-snackbar']
            })
          }
        },
        error: (err) => {
          this.logoutError = true
          this.app.snackBar.open('Error trying to logout', 'Dismiss', {
            duration: MessageUtil.TIMEOUT_DURATION,
            panelClass: ['custom-snackbar']
          })
        }
      })
  }

  gotoCalendar(){
    window.open('https://calendar.app.google/AbJx9Qn2U7ADbLH27', '_blank');
  }

}