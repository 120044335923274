import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  message: any;
  constructor( private dialog:MatDialogRef<ErrorDialogComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public selectedRow : any,) { }

  ngOnInit(): void {
    this.message = this.selectedRow
  }

  closeDialog(){
    this.dialog.close()
  }

  report(){
    this.dialog.close()
    this.router.navigate(['/app/report'])
  }

}
