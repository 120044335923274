<div *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 20px auto; height: 60vh;">
    <app-loader></app-loader>
    <div style="font-size: 14px; margin-top: 10px; font-weight: 500; color: var(--primary-color);">Just wait a
        moment...</div>
</div>

<div class="error-block" style="height: 60vh;" *ngIf="!loading && errorList">
    <app-empty-list></app-empty-list>
    <button mat-flat-button color="primary" style="padding: 0 60px;" (click)="getList()">
        Refresh
    </button>
</div>
<mat-sidenav-container class="sidenav-container" autosize *ngIf="!loading && !errorList">
    <mat-sidenav #drawer class="sidenav" [style.width]="(isHandset$ | async) ? '220px' : drawerOpen ? '220px' : '60px'"
        [style.boxShadow]="(isHandset$ | async) ? 'none' : drawerOpen ? 'none' : 'none' "
        [ngClass]="(isHandset$ | async) ? 'showSection' : drawerOpen ? 'showSection' : 'hideSection' "
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <!-- [style.borderRadius] = "(isHandset$ | async) ? '0px' : drawerOpen ? '0px' : '0px 25px 25px 0px' " -->
        <mat-toolbar [style.padding-left]="drawerOpen ? '0px' : '0px' ">
            <div class="logo-cover" *ngIf='drawerOpen'>

                <img *ngIf="brandLogo === null" src="assets/newbrandimg/datae-logo-inverted-full.png"
                    class="logofull" />
                <img *ngIf="brandLogo !== null" [src]="brandLogo" class="logofull" />
            </div>

            <!-- <div *ngIf='!(isHandset$ | async)'>
                <button class="fab_alt " [style.left]="!drawerOpen ? '50px' : '205px'" mat-fab
                    style="background: var(--bg-color);" (click)="drawerOpen = !drawerOpen">
                    <mat-icon *ngIf='drawerOpen'>menu_open</mat-icon>
                    <mat-icon *ngIf='!drawerOpen'>keyboard_arrow_right</mat-icon>

                </button>

            </div> -->
        </mat-toolbar>

        <mat-nav-list [ngClass]="{listOpen: drawerOpen, list: !drawerOpen}">
            <div>
                <div [ngClass]="{lnm: drawerOpen, snm: !drawerOpen}">
                    <div>
                        <a mat-list-item (click)="viewHome()" routerLink="/user-panel/home-page"
                            [ngClass]="{longlist: drawerOpen, shortlist: !drawerOpen}"
                            routerLinkActive="active-list-item">


                            <i class="ph-fill ph-house" [ngClass]="{longicon: drawerOpen, shorticon: !drawerOpen}"
                                routerLinkActive="iconActive"></i>

                            <span class="menu-name" *ngIf="(isHandset$ | async) ? true : drawerOpen"
                                routerLinkActive="nameActive">
                                Home
                            </span>

                        </a>
                        <div style="margin-top: 20px;">
                            <div class="reportcover" [ngClass]="{'active-list-item': isReports }"
                                (click)=" !showReport ? showReports() : hideReports()">
                                <div style="display: flex; align-items: center; gap: 10px;">
                                    <i [ngClass]="{'iconActive': isReports}" class="ph-bold ph-presentation-chart"></i>
                                    <div [ngClass]="{'nameActive': isReports}" class="reportmenu">My Reports</div>
                                </div>
                                <i class="ph-bold ph-caret-down" *ngIf="!showReport"></i>
                                <i class="ph-bold ph-caret-up" *ngIf="showReport"></i>
                            </div>


                            <div class="custom-scroll-bar" style="height: 50vh; margin-top: 10px;" *ngIf="showReport">
                                <div *ngFor="let item of reportList">
                                    <div class="listreport" (click)="viewList(item)"
                                        [ngClass]="{'active-list': item.workspaceid === id}"
                                        routerLinkActive="active-list">

                                        <span class="menu-name" *ngIf="(isHandset$ | async) ? true : drawerOpen"
                                            routerLinkActive="nameActive">
                                            {{item.reportname}}
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style="margin-top: 30px;">
                        <div class="soon" *ngIf="(isHandset$ | async) ? true : drawerOpen">Others</div>
                        <a mat-list-item href="https://datae.ae/documentation/"
                            [ngClass]="{longlist: drawerOpen, shortlist: !drawerOpen}"
                            routerLinkActive="active-list-item">
                            <i class="ph-bolder ph-file-text" routerLinkActive="iconActive"
                                [ngClass]="{longicon: drawerOpen, shorticon: !drawerOpen}"></i>
                            <span class="menu-name" *ngIf="(isHandset$ | async) ? true : drawerOpen"
                                routerLinkActive="nameActive">
                                Documentation
                            </span>

                        </a>

                        <a mat-list-item href="https://datae.ae/contact-us/"
                            [ngClass]="{longlist: drawerOpen, shortlist: !drawerOpen}"
                            routerLinkActive="active-list-item">
                            <i class="ph-bold ph-headset" routerLinkActive="iconActive"
                                [ngClass]="{longicon: drawerOpen, shorticon: !drawerOpen}"></i>
                            <span class="menu-name" *ngIf="(isHandset$ | async) ? true : drawerOpen"
                                routerLinkActive="nameActive">
                                Contact
                            </span>

                        </a>
                    </div>

                </div>





            </div>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [style.margin-left]="(isHandset$ | async) ? '0px' : drawerOpen  ? '220px' : '60px'"
        class="root-cont">
        <div class="tool-cont">
            <div class="toolbar_content">
                <button type="button" class="" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                    *ngIf="isHandset$ | async" style="color: var(--primary-color);">
                    <mat-icon color='secondary' aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <div class="bread-wrap">
                    <div class="logo-cover" *ngIf='!drawerOpen'>
                        <img *ngIf="brandLogo === null" src="assets/newbrandimg/datae-logo-inverted-full.png" class="logoshwofull" />
                        <img *ngIf="brandLogo !== null" [src]="brandLogo" class="logoshowfull" />
                    </div>
                    <app-breadcrumb></app-breadcrumb>
                </div>

                <div class="profile-wrap">
                    <div class="plan-body" (click)="gotoCalendar()" *ngIf="isTrial">
                        <img src="../../assets/svg/ph--calendar-dots-duotone.svg" alt="">
                        <!-- <i class="ph-bold ph-calendar-dot"></i>                         -->
                        <div class="current-plan">Book a call with us</div>
                    </div>

                    <div class="mode-long" style="margin-right: 20px;">
                        <a mat-list-item [ngClass]="{modeactive: lightmode == 'light'}" (click)="changeMode()"
                            routerLinkActive="active-list-item" class="mode-link" matTooltip="ligh mode">
                            <i class="ph-bold ph-sun" style="margin: 10px 0; cursor: pointer;"></i>
                        </a>

                        <a mat-list-item matTooltip="dark mode" (click)="changeDarkMode()"
                            [ngClass]="{modeactive: lightmode == 'dark'}" routerLinkActive="active-list-item"
                            class="mode-link">
                            <i class="ph-bold ph-moon" style="margin: 10px 0; cursor: pointer;"></i>

                        </a>
                    </div>
                    <!-- <i class="ph-bold ph-gear" style="cursor: pointer; margin: 0 10px;"></i> -->
                    <div class="acc1" [matMenuTriggerFor]="menu">{{getInitials(name)}}</div>
                    <mat-menu #menu="matMenu" class="menu" style="padding: 20px;" xPosition="before" yPosition="below">
                        <div class="acc-wrap">
                            <div class="acc">

                                <div class="acc2">
                                    <span class="acc3">{{name}}</span>
                                    <span class="acc4">{{username}}</span>
                                </div>
                            </div>
                            <!-- <button mat-menu-item routerLink="/app/settings" class="acc5">Profile settings</button> -->
                            <mat-divider></mat-divider>
                        </div>
                        <button mat-menu-item class="" (click)="loginType == 'google' ? signout() : logout()"
                            style="color: var(--text-color); display: flex; align-items: center; justify-content: center; color: red;">
                            <i class="ph ph-sign-out"></i>
                            <span style="margin-left: 5px;">Logout</span>
                        </button>
                    </mat-menu>
                </div>

            </div>
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>