import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UiService {
    drawerStatus = new BehaviorSubject<boolean>(true)
    $drawerStatus = this.drawerStatus.asObservable()

    setDrawerStatus(val: boolean){
        this.drawerStatus.next(val)
    }
}